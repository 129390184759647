import React from "react";
import LayoutLogged from "../../components/layout/logged";
import Container from '@material-ui/core/Container';
import AppDataGrid from "../../components/data/app-data-grid";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function OrganizationIndex() {
  if (ifNotHaveAccessRedirect('organization.write')) return <></>;

  return (
    <LayoutLogged title="Organizações">
      <Container >
        <AppDataGrid
          api='/organization'
          linksPath='/organization'
          columns={[
            { field: 'name', type: 'string', headerName: 'Nome', width: 150 },
            {
              field: 'commission_organization', type: 'number', headerName: 'Comissão org.', width: 170, sortable: true,
              valueGetter: (params) => {
                return (params?.row?.commission_organization * 100) + '%';
              }
            },
            {
              field: 'commission_users', type: 'number', headerName: 'Comissão usuário', width: 170, sortable: true,
              valueGetter: (params) => {
                return (params?.row?.commission_users * 100) + '%';
              }
            },
          ]}
          showFilterActive
          showFilterIcon
        />
      </Container>
    </LayoutLogged>
  );
}